@use "../../../../sizing.scss" as *;

.input-group-wrapper {
  position: relative;
  max-width: 100%;

  &.code-input {
    textarea,
    input {
      font-family: "Fragment Mono";
    }
  }

  // TODO MJ@Any: Check if this can be added if not remove this.
  .form-group {
    margin: 0 !important;
  }

  .label-padding {
    display: flex;
    padding-bottom: var(--space-02);

    .label-disabled {
      color: var(--text-tertiary);
    }
  }

  .message-input {
    color: var(--text-primary);

    &-disabled {
      color: var(--text-tertiary);
    }

    &-error {
      color: var(--text-contrast-danger);
    }
  }

  .input-group {
    .number-input-stepper {
      display: flex;
      justify-content: flex-end;
    }
  }

  // .input-group > input[type="number"].form-control {
  //   flex: 0 0;
  // }

  .number-input-label {
    color: var(--text-secondary);
    display: flex;
    flex: 1;
    flex-grow: 1;
  }

  textarea {
    &::placeholder {
      color: var(--text-secondary);
    }
  }

  textarea,
  textarea:focus {
    background-color: var(--surface-primary);
    color: var(--text-key);
  }

  select {
    background-color: var(--surface-primary);
  }

  &.backgrounded {
    background-color: var(--surface-primary);
    padding: 10px;
    border-radius: 4px;

    .input-group {
      padding: 0;
    }
  }

  .ql-container {
    min-height: 100px;
  }

  .ql-container,
  .ql-toolbar {
    border: 0 !important;
  }

  .ql-toolbar {
    padding-bottom: 0px !important;

    svg {
      color: var(--text-color);
      fill: var(--text-color) !important;
      stroke: var(--text-color) !important;
      cursor: pointer;

      .ql-stroke {
        stroke: var(--text-color) !important;
      }
    }
  }

  .ql-picker-label {
    color: var(--text-color);
  }

  .ql-picker.ql-size .ql-picker-label[data-value="0.75em"]::before,
  .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before {
    content: "Small";
  }

  .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before {
    font-size: 0.75em !important;
  }

  .ql-picker.ql-size .ql-picker-label[data-value="1em"]::before,
  .ql-picker.ql-size .ql-picker-item[data-value="1em"]::before {
    content: "Normal";
  }

  .ql-picker.ql-size .ql-picker-item[data-value="1em"]::before {
    font-size: 1em !important;
  }

  .ql-picker.ql-size .ql-picker-label[data-value="1.5em"]::before,
  .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {
    content: "Large";
  }

  .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {
    font-size: 1.5em !important;
  }

  .ql-picker.ql-size .ql-picker-label[data-value="2.5em"]::before,
  .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before {
    content: "Huge";
  }

  .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before {
    font-size: 2.5em !important;
  }
}

.input-group-wrapper .search-icon {
  right: 10px;
}

.input-group-wrapper .form-control {
  border-radius: 0;
  border-color: transparent;
  padding: 0;
  margin: 0;
  height: auto;
  z-index: 1000;
}

.input-group-wrapper.input-error .position-relative .input-group {
  outline-offset: calc(-2 * var(--border-01));
  outline: var(--border-02) solid var(--outline-contrast-danger) !important;

  &:hover,
  &:focus-within {
    outline-offset: calc(-2 * var(--border-01));
    outline: var(--border-02) solid var(--outline-contrast-danger) !important;
  }
}

.input-group-wrapper .input-group:has(textarea) {
  align-items: flex-start;
}

.input-group-wrapper.number-input .input-group {
  padding: var(--space-04) var(--space-06);
  flex-wrap: nowrap;
}

.input-group-wrapper .input-group input[readonly],
.input-group-wrapper .input-group input[disabled] {
  pointer-events: none;
  outline: none;
}

.input-group-wrapper .input-group select[readonly],
.input-group-wrapper .input-group select[disabled] {
  background-color: var(--white);
}

.input-group-wrapper .input-group input[disabled],
.input-group-wrapper .input-group input[disabled]::placeholder {
  color: var(--text-tertiary);
}

.input-group-wrapper .input-group .disabled-dropdown .dropdown-selected-item {
  color: var(--text-tertiary);
}

.input-group-wrapper .input-group {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--space-04);
  align-self: stretch;
  border-radius: var(--radius-02);
  padding: 0;
  background: var(--surface-primary);

  &:has(select) {
    padding: var(--space-06);
  }

  input,
  .time-picker-container,
  .dropdown,
  textarea,
  .StripeElement {
    padding: var(--space-06) !important;
    border-radius: var(--radius-02);
  }

  .StripeElement {
    background: var(--surface-primary);
  }

  .dropdown {
    width: 100%;

    .title-dropdown-menu {
      // TODO MJ@Any: check if this can be done better. This is used so menu is placed as it should be.
      margin-bottom: 20px;
      margin-top: 20px;
      margin-right: -12px !important;
      margin-left: -12px !important;
      padding: 0px;
      box-shadow: 0px 4px 12px 0px var(--black-s);
      border-radius: var(--radius-02);
      display: flex;
      flex-direction: column;

      .list-unstyled {
        display: flex;
        flex-direction: column;
        padding: var(--space-06);
        gap: var(--space-02);

        .dropdown-item {
          all: unset;
        }
      }
    }

    .dropdown-select-toggle {
      display: flex;
      height: 20px;
      padding: 0px;
      background-color: inherit;
      border: none;

      .dropdown-selected-item {
        flex: 1;

        .placeholder-text {
          color: var(--text-secondary);

          &-disabled {
            color: var(--text-tertiary);
          }
        }
      }
    }
  }

  &:has(.show.dropdown .dropdown-select-toggle) {
    outline-offset: calc(-2 * var(--border-01));
    outline: var(--border-02) solid var(--outline-contrast-brand) !important;
  }

  &:has(input[readonly]),
  &:has(input[disabled]) {
    background: transparent !important;
    outline-offset: calc(-1 * var(--border-01));
    outline: var(--border-01) solid var(--outline-tertiary) !important;
  }

  &:has(.readonly-dropdown),
  &:has(.disabled-dropdown) {
    background: transparent !important;
    outline-offset: calc(-1 * var(--border-01));
    outline: var(--border-01) solid var(--outline-tertiary) !important;
  }

  &:not(:has(input[readonly]), :has(input[disabled])) {
    &:hover {
      outline-offset: calc(-1 * var(--border-01));
      outline: var(--border-01) solid var(--outline-tertiary) !important;
    }
  }

  &:has(select[readonly]),
  &:has(select[disabled]) {
    background: transparent !important;
  }

  &:focus-within {
    &:not(:has(input[readonly])) {
      outline-offset: calc(-2 * var(--border-01));
      outline: var(--border-02) solid var(--outline-contrast-brand) !important;

      .custom-file-label {
        border-color: transparent !important;
      }
    }
  }

  .quill {
    border-radius: var(--radius-02);
  }
}

.input-group-wrapper.number-input {
  input,
  .time-picker-container,
  .dropdown,
  textarea {
    padding: 0 !important;
  }
}

textarea {
  border-radius: var(--radius-02) !important;
}

.input-transparent .input-group {
  background-color: transparent !important;
  width: 100%;
  border-radius: 0;
  border: 0;
  border-bottom: 3px solid var(--text-color) !important;
  padding: 0;

  .form-control {
    background-color: transparent !important;
  }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.input-group-icon {
  fill: var(--accent-03);
}

.input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.error-icon,
.success-icon {
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
  width: 15px;
  font-weight: bold;
  height: 15px;
  display: block;
  color: #fff;
  right: 10px;
  z-index: 1000;
}

.error-icon {
  background-color: var(--error-color);
}

.success-icon {
  background-color: #53c462;
}

.error-message {
  color: var(--error-color);
  display: block;
  margin-top: 5px;
}

.error-message-icon {
  margin-right: 6px;

  .icon-size {
    width: 18px;
    height: 18px;
    margin-bottom: 4px;
    padding: 0;
  }
}

.form-label-small {
  color: var(--text-color);
  font-size: 12px;
}

.form-description {
  color: var(--text-color);
  font-size: 12px;
  line-height: 14px;
  display: flex;
  margin: 10px 0;
}

.form-input-maxlength {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--space-02);
  color: var(--text-primary);

  &.maxlength-disabled {
    color: var(--text-tertiary) !important;
  }

  &.maxlength-error {
    color: var(--text-contrast-danger) !important;
  }

  &.maxlength-message {
    justify-content: space-between;
  }
}

.input-length-exceeded {
  color: #dc3545;
}

.input-gray {
  .input-group {
    background-color: var(--surface-color);

    .form-control {
      background-color: transparent;
    }
  }
}

.rich-text-input {
  .input-group {
    padding: 0px;

    .ql-toolbar {
      background-color: var(--surface-panel);
      padding: var(--space-05) var(--space-04) var(--space-03) var(--space-04) !important;
    }

    .action-bar-form {
      width: 100%;

      textarea {
        padding: var(--space-06);
      }

      .ql-container {
        position: static;
      }

      .action-bar-input {
        background-color: var(--surface-panel);
        padding: var(--space-03) var(--space-04) var(--space-05) var(--space-04);
        border-radius: 0 0 var(--radius-02) var(--radius-02);
        display: flex;

        &-end-icons {
          display: flex;
          flex: 1;
          justify-content: flex-end;
        }

        &-start-icons {
          display: flex;
        }
      }
    }
  }
}

.chat-email-input {
  width: 100%;
  margin: 0 !important;

  .form-group {
    margin: 0;
  }

  .input-group {
    border: none;
    padding: 0;

    .text-area {
      .ql-container {
        display: flex;
        flex-direction: column;
      }

      .ql-container,
      .ql-snow {
        font-size: inherit;
        font-family: inherit;
      }

      .ql-editor {
        flex: 1;
        max-height: 100px;
        overflow: auto;
        word-break: break-word;
      }

      .ql-blank:before {
        font-style: normal;
      }
    }
  }
}

.expanded {
  .input-group {
    .text-area {
      .ql-editor {
        max-height: 100%;
      }
    }
  }
}

.input-extend {
  flex: 1;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
}

.custom-file-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 78px;
  border-radius: 12px;
}

.input-group #card {
  display: grid;

  .__PrivateStripeElement {
    align-self: center;
  }
}

.input-group select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.timezone-picker-background input {
  background-color: transparent !important;
}

.input-group .timezone-picker ul {
  background-color: var(--surface-panel);
  border-radius: var(--radius-02) !important;
  right: -12px;
  left: -12px;
  top: calc(100% + 20px);
}

.input-group .timezone-picker.timezone-picker-open-up ul {
  background-color: var(--surface-panel);
  border-radius: var(--radius-02) !important;
  right: -12px;
  left: -12px;
  bottom: calc(100% + 20px) !important;
  top: auto;
}

.input-group .timezone-picker.limit-timezone-picker-height ul {
  max-height: 120px;
}

.input-group .rc-time-picker .rc-time-picker-input {
  background-color: transparent;
}

.rc-time-picker .rc-time-picker-clear {
  top: 0;
  bottom: 0;
  right: -10px;
}

.input-group .rc-time-picker-input {
  padding: 0;
  height: auto;
  font-size: inherit;
  border: 0;
}

.input-group-labels {
  align-items: center;
  margin-bottom: 8px !important;
}

.input-group-append {
  height: 52px;
  margin: -10px -10px -10px 10px;

  .input-group-text {
    border-radius: 0 12px 12px 0;
    background-color: var(--accent-03-a) !important;
    color: var(--on-secondary-color);
  }

  z-index: 101;
}

.ql-picker.ql-font {
  width: 150px !important;

  .ql-picker-item {
    font-size: 0;

    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="arial"]::before,
.ql-font-arial {
  font-family: Arial, Helvetica, sans-serif;
  content: "Arial" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="couriernew"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="couriernew"]::before,
.ql-font-couriernew {
  font-family: "Courier New", serif;
  content: "Courier New" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="garamond"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="garamond"]::before,
.ql-font-garamond {
  font-family: garamond, "times new roman", serif;
  content: "Garamond" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="georgia"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before,
.ql-font-georgia {
  font-family: Georgia, serif;
  content: "Georgia" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="helvetica"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="helvetica"]::before,
.ql-font-gelvetica {
  font-family: Helvetica, sans-serif;
  content: "Helvetica" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="impact"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="impact"]::before,
.ql-font-impact {
  font-family: Impact, Charcoal, sans-serif;
  content: "Impact" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="lucida"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="lucida"]::before,
.ql-font-lucida {
  font-family: "Lucida Console", "Courier New", serif;
  content: "Lucida Console" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="timesnewroman"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="timesnewroman"]::before,
.ql-font-timesnewroman {
  font-family: "Times New Roman", Times, serif, -webkit-standard;
  content: "Times New Roman" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="tahoma"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="tahoma"]::before,
.ql-font-tahoma {
  font-family: Tahoma, Geneva, Helvetica, sans-serif;
  content: "Tahoma" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="trebuchet"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="trebuchet"]::before,
.ql-font-trebuchet {
  font-family: "trebuchet ms", Helvetica, sans-serif;
  content: "Trebuchet MS" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="verdana"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="verdana"]::before,
.ql-font-verdana {
  font-family: Verdana, Geneva, Helvetica, sans-serif;
  content: "Verdana" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before,
.ql-font-monospace {
  font-family: monospace;
  content: "Fixed width" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="wide"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="wide"]::before,
.ql-font-wide {
  font-family: "arial black", sans-serif;
  content: "Wide" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="narrow"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="narrow"]::before,
.ql-font-narrow {
  font-family: "arial narrow", sans-serif;
  content: "Narrow" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="comicsansms"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="comicsansms"]::before,
.ql-font-comicsansms {
  font-family: "comic sans ms", sans-serif;
  content: "Comic Sans MS" !important;
}

.expand-icon-email {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  cursor: pointer;
  z-index: 1;
}

.preview-icon {
  width: 20px;
  height: 20px;
  margin-top: 5px;
  cursor: pointer;
  fill: var(--text-color);
  z-index: 1;
}

.quill.form-control {
  background-color: var(--surface-primary);
  color: var(--text-color);

  .ql-editor {
    font-size: initial;

    &:before {
      color: var(--text-secondary);
    }
  }
}

.input-group-error {
  .input-group,
  .input-group:hover,
  .input-group:focus-within {
    border: 1px solid var(--error-color) !important;
  }
}

.DateInput_fang {
  display: none;
}

.DayPickerKeyboardShortcuts_buttonReset {
  visibility: hidden;
}

.DateRangePickerInput_arrow_svg {
  fill: var(--text-color) !important;
}

.DateRangePickerInput {
  padding: var(--space-06) !important;
}

.DateRangePicker,
.SingleDatePicker {
  .DateRangePickerInput,
  .SingleDatePickerInput {
    display: flex !important;
    border: none !important;
    border-radius: var(--radius-02) !important;
    align-items: center;

    .input-calendar-icon {
      fill: var(--object-primary);
    }

    &:hover {
      outline-offset: calc(-1 * var(--border-01));
      outline: var(--border-01) solid var(--outline-tertiary) !important;

      .input-calendar-icon {
        fill: var(--object-primary);
      }
    }

    &:focus-within {
      outline-offset: calc(-2 * var(--border-01));
      outline: var(--border-02) solid var(--outline-contrast-brand) !important;

      .input-calendar-icon {
        fill: var(--object-key);
      }
    }

    .DateInput_input__focused {
      .input-calendar-icon {
        fill: var(--object-primary);
      }
    }

    .CalendarDay {
      height: 39px !important;
      width: 39px !important;
      color: var(--text-key);
    }

    .CalendarDay__today {
      position: relative;

      &::after {
        content: "";
        border: var(--border-01) solid var(--outline-key);
        border-radius: 50%;
        position: absolute;
        height: 39px;
        width: 39px;
        top: 0;
        left: 0;
      }

      &:hover {
        border-radius: 50%;
      }
    }

    .CalendarDay__selected_span {
      background: var(--surface-contrast-alpha-brand) !important;
      border-color: var(--surface-contrast-alpha-brand) !important;
    }

    .CalendarDay__selected_span:hover {
      color: var(--text-key);
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: var(--surface-contrast-alpha-brand) !important;
      border-color: var(--surface-contrast-alpha-brand) !important;
    }

    .CalendarDay__selected_start {
      border-radius: 18px 0px 0px 18px;
    }

    .CalendarDay__selected_end {
      border-radius: 0px 18px 18px 0px;
    }

    .CalendarDay__blocked_out_of_range {
      color: var(--text-primary) !important;
    }

    .DateRangePickerInput_calendarIcon {
      padding: 0px !important;
      margin: 0px 0px 0px 8px !important;
    }

    .DateRangePicker_picker,
    .SingleDatePicker_picker {
      overflow: hidden;
      border-radius: var(--radius-02);
      border: var(--border-01) solid var(--outline-secondary);
      background: var(--surface-panel);
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.5);

      .DayPickerNavigation_button__default {
        border: none;
      }

      .DayPicker_transitionContainer {
        .CalendarDay__selected {
          border: none !important;
        }
      }

      .DayPicker_weekHeaders {
        .DayPicker_weekHeader:nth-of-type(2) {
          left: 280px !important;
        }
      }

      // .DayPicker_weekHeader_li {
      //   width: 36px !important;
      // }
    }
  }
}

.SingleDatePickerInput_calendarIcon {
  padding: 0px !important;
  margin: 0px 0px 0px 0px !important;
}

.SingleDatePickerInput__withBorder {
  border: none !important;
}

.SingleDatePicker .SingleDatePickerInput {
  &.DateInput_input__readOnly,
  &.DateInput_input__disabled {
    background-color: transparent !important;
    pointer-events: none !important;
  }
}

.SingleDatePicker {
  .SingleDatePickerInput {
    display: flex;
    gap: var(--space-04);
    background-color: transparent !important;

    .DateInput_input {
      background-color: transparent !important;

      &__disabled {
        background-color: transparent !important;
        font-style: normal;
        color: var(--text-secondary) !important;
      }

      &__readOnly {
        background-color: transparent !important;
      }
    }

    &:has(.DateInput_input__readOnly) {
      .input-calendar-icon {
        fill: var(--object-secondary) !important;
      }
    }

    &:has(.DateInput_input__disabled) {
      .input-calendar-icon {
        fill: var(--object-tertiary) !important;
      }
    }
  }
}

.DateInput_input {
  @extend .large;
  padding: 0px !important;
}

//PAINT DATEPICKER
.SingleDatePicker_picker,
.DayPicker,
.DayPicker_weekHeaders,
.DayPicker_weekHeader,
.DayPicker_focusRegion,
.DayPickerNavigation,
.DayPicker_transitionContainer,
.CalendarMonthGrid,
.CalendarMonthGrid_month__horizontal,
.CalendarMonth,
.CalendarMonthGrid_month__horizontal,
.DayPickerNavigation_button,
.DayPickerKeyboardShortcuts_panel {
  color: var(--text-color) !important;
  background: var(--background-color) !important;
}

.DateInput {
  width: 100%;
  background: transparent !important;
}

.CalendarMonth_table {
  margin-top: var(--space-03);
}

.DayPickerNavigation_button {
  svg {
    fill: var(--text-color);
  }
}

.CalendarMonth_caption strong {
  color: var(--text-color);
}

.CalendarDay.CalendarDay__blocked_out_of_range {
  background-color: var(--background-color);

  &:hover {
    background-color: var(--background-color);
  }
}

.CalendarDay__default:not(.CalendarDay__blocked_out_of_range):not(.CalendarDay__selected):not(
    .CalendarDay__selected_span
  ) {
  background-color: var(--background-color) !important;
  color: var(--text-key) !important;

  &:hover {
    background-color: var(--accent-03-a) !important;
  }
}

.CalendarDay__selected {
  background: var(--surface-contrast-alpha-brand) !important;
  border: 1px double var(--surface-contrast-alpha-brand) !important;
  border-radius: 18px;
  color: var(--text-key) !important;
}

.CalendarDay__blocked_out_of_range {
  color: var(--text-primary) !important;
}

.DayPickerKeyboardShortcuts_showSpan {
  color: var(--text-key) !important;
}

//end painting datepicker

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--surface-primary) inset !important;
  border: 0;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: var(--text-color) !important;
}

// .input-group:focus-within,
// .form-control:focus-within,
// .form-control:focus:focus-within {
//   outline: none;
// }

.search-on-master-login {
  width: 100%;
  background-color: var(--surface-primary) !important;
}

.input-on-surface {
  color: var(--accent-03);
}

.edit-team {
  height: 25px;
  border-bottom: 2px solid var(--accent-03);
  width: 500px;

  .input-group {
    border: none;
    width: fit-content;
    bottom: 16px;
    padding: 0;
  }

  .form-control {
    font-size: 24px;
    width: 500px;
  }

  .error-message {
    margin-top: -10px;
    font-size: 15px;
    font-weight: lighter;
  }

  .error-icon {
    visibility: hidden;
  }
}

.input-error.edit-team {
  border-bottom: 2px solid var(--error-color);
}

.sender-name-input-group {
  .form-input-maxlength {
    margin-top: 8px;
  }

  .input-group-labels {
    margin-bottom: 0 !important;
  }
}

.input-group-wrapper {
  .password-icon,
  .copy-icon,
  .chevron-down-icon,
  .input-calendar-icon {
    width: 20px;
    height: 20px;
    margin-right: var(--space-06);
    cursor: pointer;
    fill: var(--object-primary);

    &-readonly {
      fill: var(--object-secondary);
    }

    &-disabled {
      fill: var(--object-tertiary);
    }
  }

  .chevron-down-icon {
    margin-right: 0;
  }

  &.input-error {
    .password-icon,
    .copy-icon,
    .chevron-down-icon,
    .input-calendar-icon {
      fill: var(--object-key);
    }
  }

  &:focus-within,
  .show.dropdown {
    .password-icon,
    .copy-icon,
    .chevron-down-icon,
    .input-calendar-icon {
      fill: var(--object-key);
    }
  }

  .password-icon,
  .copy-icon {
    &:hover {
      fill: var(--object-key);
    }
  }
}

.input-group-wrapper {
  position: relative;
  cursor: pointer;
  .chevron-down-icon {
    pointer-events: none;
  }
}

.hr-divider-icon {
  width: 16px;
  height: 28px;
  stroke: var(--outline-primary);
}
